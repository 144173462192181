import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There has not been much activity on this website recently but quite a lot of
material has been  published as extended abstracts and refereed publications in
the last 18 months. These items may  prove useful to those following
Gondwana-related research and exploration interests. The list  below summarises
what has appeared. I will do my best to provide copies in response to e-mail
requests. `}</p>
    <PublishDate mdxType="PublishDate" />
    <p><strong parentName="p">{`Key, R.M., and Reeves, C.V., 2012.`}</strong>{` The post-Gondwana development of East
Africa’s coastline  with emphasis on the development of the Rovuma Basin.
Extended abstract. Geological Society  meeting, London, October 24-26. `}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013a.`}</strong>{` African Geology and Tectonics through aeromagnetics:
from Botswana to  Gondwana. Extended abstract. 24th Colloquium of African
Geology. Addis Ababa, January. Reeves, C.V., 2013b. The global tectonics of the
Indian Ocean and its relevance to India’s  western margin. Journal of
Geophysics, vol 34, 87-94. `}</p>
    <p><strong parentName="p">{`Reeves, C.V. and Mahanjane, E.S., 2013a.`}</strong>{` Dykes in SE Africa reveal first
successful Gondwana  spreading axis. Letter to Nature, April 2013 – rejected. `}</p>
    <p><strong parentName="p">{`Reeves, C.V., and Mahanjane, E.S., 2013b.`}</strong>{` Mozambique and its role in the
downfall of  Gondwana. Extended abstract. Geological Society of
Houston/Petroleum exploration society of  Great Britain, London, September
11-12. `}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013d.`}</strong>{` Global tectonics, Rifting and the fabric of Africa.
Extended abstract. Society  of Exploration Geophysicists, ‘Exploration of
Continental Rifts’ workshop, Houston, September 26. Reeves, C.V., 2013e.
Lebombo: Are we on the edge of Africa? Extended abstract. South African
Geophysical Association meeting, Skukuza, South Africa, October. `}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2014.`}</strong>{` The position of Madagascar within Gondwana and its
movements during  Gondwana dispersal. Journal of African Earth Sciences. Volume,
94, 45-57.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      